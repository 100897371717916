import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [lang, setLang] = useState("ar");
  const { i18n } = useTranslation();

  const changeLanguage = (newLang) => {
    i18n.changeLanguage(newLang);
  };

  const [images, setImages] = useState();
  const unmount = useRef(false);
  useEffect(() => {
    const getImages = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}api/getImageHome`
        );

        // console.log(res.data);
        setImages(res.data.flatMap((group) => group.images));
      } catch (error) {
        console.log(error.message);
      }
    };
    if (!unmount.current) {
      getImages();
      unmount.current = true;
    }
  }, []);

  const [selectedAreas, setSelectedAreas] = useState();

  return (
    <LanguageContext.Provider
      value={{
        lang,
        setLang,
        changeLanguage,
        images,
        selectedAreas,
        setSelectedAreas,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};
