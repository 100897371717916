import React, { useContext } from "react";
import "./SignUp.css";
import Helmet from "ui/helmet/Helmet";
import { Link, useLocation } from "react-router-dom";
import commonImg from "assets/images/brown.png";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "context/LanguageContext";
import {
  Button,
  Col,
  Form,
  OverlayTrigger,
  Popover,
  Row,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "rtk/slices/CartSlice";
import { useForm } from "react-hook-form";

const Login = () => {
  const productsInState = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const { lang, setLang, changeLanguage } = useContext(LanguageContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
  };
  const location = useLocation();

  let backLink = "/";

  if (location.pathname === "/store") {
    // If the current location is '/store', set the link to '/'
    backLink = "/";
  } else {
    // If the current location is '/category', set the link to '/store'
    backLink = "/store";
  }
  return (
    <section className="signUpSection">
      <Helmet title="Register" />
      <div className="cartHeader">
        <div className={`container ${lang === "ar" ? "containerRe_ar" : ""}`}>
          <Link to={"/store"}>
            <img src={commonImg} alt="homeImg" />
            <h1>sweet_a2z company</h1>
          </Link>
          <div className="rightSideH">
            <Link to={backLink} className="searchIconH">
              <i className="ri-arrow-left-line"></i>
            </Link>
            <OverlayTrigger
              trigger="click"
              rootClose
              placement={"bottom"}
              style={{ height: "1000px" }}
              overlay={
                <Popover id={`popover-positioned-bottom`}>
                  <Popover.Body className="pop">
                    {productsInState.cartItems.length > 0 ? (
                      <>
                        <div className="PopCards">
                          {productsInState.cartItems.map((item) => (
                            <div className="PopCardsCard" key={item.id}>
                              <div className="PopCardsImg">
                                <img
                                  src={`${process.env.REACT_APP_API_URL}${item.image[0].url}`}
                                  alt="imgProduct"
                                />
                              </div>
                              <div className="PopCardsdetails">
                                <h4>{item.name}</h4>
                                <div className="quantityprice">
                                  {item.quantity} x {item.price} {t("currency")}
                                </div>
                              </div>
                              <div
                                className="btnTrash"
                                onClick={() =>
                                  dispatch(cartActions.deleteItem(item.id))
                                }
                              >
                                {" "}
                                <svg
                                  className="h-5 w-5  ltr:mr-1 rtl:ml-1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="17.107"
                                  height="19.687"
                                  viewBox="0 0 17.107 19.687"
                                >
                                  <g
                                    id="Group_9777"
                                    data-name="Group 9777"
                                    transform="translate(-3708.868 -2157.542)"
                                  >
                                    <g
                                      id="Group_650"
                                      data-name="Group 650"
                                      transform="translate(3708.868 2157.542)"
                                    >
                                      <path
                                        id="Path_331"
                                        data-name="Path 331"
                                        d="M15.7,36.122V49.807a2.945,2.945,0,0,1-2.973,2.917H4.319A2.9,2.9,0,0,1,1.4,49.807V36.122H.561A.562.562,0,0,1,0,35.561.53.53,0,0,1,.561,35H16.546a.562.562,0,0,1,.561.561.553.553,0,0,1-.561.5Zm-13.181,0V49.807A1.807,1.807,0,0,0,4.319,51.6h8.413a1.82,1.82,0,0,0,1.851-1.795V36.122Z"
                                        transform="translate(0 -33.037)"
                                        fill="#f40000"
                                      ></path>
                                      <path
                                        id="Path_332"
                                        data-name="Path 332"
                                        d="M90,105.561a.562.562,0,0,1,.561-.561.53.53,0,0,1,.561.561v8.75a.53.53,0,0,1-.561.561.562.562,0,0,1-.561-.561Z"
                                        transform="translate(-84.952 -99.111)"
                                        fill="#f40000"
                                      ></path>
                                      <path
                                        id="Path_333"
                                        data-name="Path 333"
                                        d="M195,105.561a.53.53,0,0,1,.561-.561.562.562,0,0,1,.561.561v8.75a.562.562,0,0,1-.561.561.53.53,0,0,1-.561-.561Z"
                                        transform="translate(-184.063 -99.111)"
                                        fill="#f40000"
                                      ></path>
                                      <path
                                        id="Path_334"
                                        data-name="Path 334"
                                        d="M142,105.561a.562.562,0,0,1,.561-.561.53.53,0,0,1,.561.561v8.75a.53.53,0,0,1-.561.561.562.562,0,0,1-.561-.561Z"
                                        transform="translate(-134.036 -99.111)"
                                        fill="#f40000"
                                      ></path>
                                      <path
                                        id="Path_335"
                                        data-name="Path 335"
                                        d="M108.561,1.122a.561.561,0,1,1,0-1.122h3.814a.53.53,0,0,1,.561.561.53.53,0,0,1-.561.561Z"
                                        transform="translate(-101.943)"
                                        fill="#f40000"
                                      ></path>
                                    </g>
                                  </g>
                                </svg>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="buttons">
                          <Link to={"/store/cart"} className="viewCartBtn">
                            {t("buttonReaview")}
                          </Link>
                          <button
                            className="viewCartBtn"
                            onClick={() => dispatch(cartActions.clearCart())}
                          >
                            {t("buttonEmpty")}
                          </button>
                        </div>
                      </>
                    ) : (
                      <h4>{t("titleEmpty")}</h4>
                    )}
                  </Popover.Body>
                </Popover>
              }
            >
              <div className="header_logo logoCart">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.691"
                  height="25"
                  viewBox="0 0 19.691 25"
                  className="fill-black"
                >
                  <path
                    id="Path_8965"
                    data-name="Path 8965"
                    d="M32.526,10.38H29.137V9.621a5.667,5.667,0,1,0-11.334,0v.759H14.413a.8.8,0,0,0-.813.813V26.812A2.089,2.089,0,0,0,15.688,28.9H31.2a2.089,2.089,0,0,0,2.088-2.088V11.167A.733.733,0,0,0,32.526,10.38Zm-13.1-.786a4.04,4.04,0,1,1,8.08,0v.759H19.43Zm11.8,17.679H15.715a.486.486,0,0,1-.488-.488V11.98H31.713v14.8A.486.486,0,0,1,31.225,27.273Z"
                    transform="translate(-13.6 -3.9)"
                  ></path>
                </svg>
                <span className="numCart">{productsInState.totalQuantity}</span>
              </div>
            </OverlayTrigger>
            <div
              className={`lng ${lang === "ar" ? "d-none" : ""}`}
              onClick={() => {
                changeLanguage("ar");
                setLang("ar");
              }}
            >
              {lang === "en" ? "ع" : "en"}
            </div>
            <div
              className={`lng ${lang === "en" ? "d-none" : ""}`}
              onClick={() => {
                changeLanguage("en");
                setLang("en");
              }}
            >
              {lang === "en" ? "ع" : "en"}
            </div>
          </div>
        </div>
      </div>
      <div className="formContainer my-5">
        <div className="container p-3">
          <div className="formHeaderS">
            <h2>{t("welcome")}</h2>
            <p>{t("LoginTitle")}</p>
          </div>
          <div className="signForm">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col className="my-3">
                  <Form.Group controlId="formName">
                    <Form.Control
                      type="email"
                      placeholder={t("Email")}
                      {...register("email")}
                    />
                    <Form.Text className="text-danger">
                      {errors.email && errors.email.message}
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col className="my-3">
                  <Form.Group controlId="formPassword">
                    <Form.Control
                      type="password"
                      placeholder={t("password")}
                      {...register("password")}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Button className="modalDateBtn col-lg-12" type="submit">
                {t("Submit")}
              </Button>
            </Form>
          </div>
          <div className="existLink my-3 text-center">
            <span>{t("newUser")}?</span>{" "}
            <Link to={"/store/signup"}>{t("SignUp")}</Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
