import React, { useContext, useEffect, useState } from "react";
import "./Header.css";
import { Link, useLocation } from "react-router-dom";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "rtk/slices/CartSlice";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "context/LanguageContext";
import ModalRegister from "ui/modalRegister/ModalRegister";

const Header = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    window.scrollY > 60 ? setScrolled(true) : setScrolled(false);
    window.addEventListener("scroll", () => {
      window.scrollY > 60 ? setScrolled(true) : setScrolled(false);
    });
  }, []);

  const productsInState = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { lang, setLang, changeLanguage } = useContext(LanguageContext);

  const [showRegister, setShowRegister] = useState(false);
  const handleCloseRegister = () => setShowRegister(false);
  const handleShowRegister = () => {
    setShowRegister(true);
  };
  const location = useLocation();
  const formatImageUrl = (imageUrl) => {
    const baseUrl = process.env.REACT_APP_API_URL;
    if (!imageUrl) return "";
    // Check if the imageUrl already includes the baseUrl
    if (imageUrl.startsWith(baseUrl)) {
      return imageUrl;
    }
    // Check if imageUrl starts with "http" which means it's a complete URL
    if (imageUrl.startsWith("http")) {
      return imageUrl;
    }
    // Otherwise, concatenate the baseUrl and the imageUrl
    return `${baseUrl}${imageUrl}`;
  };
  let backLink = "/";

  if (location.pathname === "/store") {
    // If the current location is '/store', set the link to '/'
    backLink = "/";
  } else {
    // If the current location is '/category', set the link to '/store'
    backLink = "/store";
  }

  return (
    <header className={`header ${scrolled ? "scrolled" : ""}`}>
      <div
        className={`header_row container ${
          lang === "ar" ? "header_row_ar" : ""
        }`}
      >
        <div className="leftSide">
          <Link
            to={backLink}
            className={`search ${lang === "ar" ? "d-none" : ""}`}
          >
            <i className="ri-arrow-left-line"></i>
          </Link>
          <Link to={"/store/search"} className="search">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 15 15"
              className="fill-gray-700 w-5 h-5"
            >
              <path
                id="Path_277"
                data-name="Path 277"
                d="M16.768,15.715l-2.781-2.76A6.743,6.743,0,1,0,12.946,14l2.758,2.76a.745.745,0,1,0,1.064-1.043ZM8.741,14a5.251,5.251,0,1,1,5.247-5.251A5.249,5.249,0,0,1,8.741,14Z"
                transform="translate(-1.979 -1.979)"
              ></path>
            </svg>
          </Link>
          <Link to={""} className="search" onClick={handleShowRegister}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 21.78 25"
              className="fill-black mt-1"
            >
              <g
                id="Group_8844"
                data-name="Group 8844"
                transform="translate(-24.814 -22.475)"
              >
                <path
                  id="Path_338"
                  data-name="Path 338"
                  d="M41.016,34.732a5.654,5.654,0,1,1,5.652-5.654A5.659,5.659,0,0,1,41.016,34.732Zm0-9.755a4.1,4.1,0,1,0,4.1,4.1A4.1,4.1,0,0,0,41.016,24.977Z"
                  transform="translate(-5.104 -0.949)"
                ></path>
                <path
                  id="Path_339"
                  data-name="Path 339"
                  d="M46.632,62.583H26.693a.921.921,0,0,1-.921-.921c0-6.138,5.089-11.522,10.89-11.522,5.9,0,10.89,5.276,10.89,11.522A.92.92,0,0,1,46.632,62.583ZM27.658,60.742H45.666c-.464-4.763-4.472-8.76-9-8.76S28.123,55.98,27.658,60.742Z"
                  transform="translate(-0.958 -15.109)"
                ></path>
              </g>
            </svg>
          </Link>
          <ModalRegister
            title={`${t("Categories")}`}
            show={showRegister}
            handleClose={handleCloseRegister}
          >
            <div className="Category_modal">
              <div className="listOfRegister">
                <h5>{t("EnableQuick")}</h5>
                <div>
                  <i className="ri-bank-card-line"></i>
                  <span>{t("Savecards")}</span>
                </div>
                <div>
                  <i className="ri-map-pin-line"></i>
                  <span>{t("Saveaddresses")}</span>
                </div>
                <div>
                  <i className="ri-loop-right-line"></i>
                  <span>{t("1-ClickRe-order")}</span>
                </div>
              </div>

              <div className="createAcountBtn my-3">
                <Link to={"/store/signup"}>{t("CreateAnAccount")}</Link>
              </div>
              <div className="alreadyLogin">
                <span>{t("Existinguser")}</span>{" "}
                <Link to={"/store/login"}>{t("Login")}</Link>
              </div>
            </div>
          </ModalRegister>
        </div>
        <div className={`rightSideH`}>
          <Link
            to={backLink}
            className={`search ${lang === "en" ? "d-none" : ""}`}
          >
            <i className="ri-arrow-left-line"></i>
          </Link>
          <div
            className={`search ${lang === "ar" ? "d-none" : ""}`}
            onClick={() => {
              changeLanguage("ar");
              setLang("ar");
            }}
          >
            {lang === "en" ? "ع" : "ENG"}
          </div>
          <div
            className={`search ${lang === "en" ? "d-none" : ""}`}
            onClick={() => {
              changeLanguage("en");
              setLang("en");
            }}
          >
            {lang === "en" ? "ع" : "ENG"}
          </div>

          <OverlayTrigger
            trigger="click"
            rootClose
            placement={"bottom"}
            style={{ height: "1000px" }}
            overlay={
              <Popover id={`popover-positioned-bottom`}>
                <Popover.Body className="pop">
                  {productsInState.cartItems.length > 0 ? (
                    <>
                      <div className="PopCards">
                        {productsInState.cartItems.map((item) => (
                          <div className="PopCardsCard" key={item.id}>
                            <div className="PopCardsImg">
                              <img
                                src={formatImageUrl(
                                  item.imageUrl || item.image?.[0]?.url || ""
                                )}
                                alt="imgProduct"
                              />
                            </div>

                            <div className="PopCardsdetails">
                              <h4>{item.name}</h4>
                              <div className="quantityprice">
                                {item.quantity} x {item.price} KWD
                              </div>
                            </div>
                            <div
                              className="btnTrash"
                              onClick={() =>
                                dispatch(cartActions.deleteItem(item.id))
                              }
                            >
                              {" "}
                              <svg
                                className="h-5 w-5  ltr:mr-1 rtl:ml-1"
                                xmlns="http://www.w3.org/2000/svg"
                                width="17.107"
                                height="19.687"
                                viewBox="0 0 17.107 19.687"
                              >
                                <g
                                  id="Group_9777"
                                  data-name="Group 9777"
                                  transform="translate(-3708.868 -2157.542)"
                                >
                                  <g
                                    id="Group_650"
                                    data-name="Group 650"
                                    transform="translate(3708.868 2157.542)"
                                  >
                                    <path
                                      id="Path_331"
                                      data-name="Path 331"
                                      d="M15.7,36.122V49.807a2.945,2.945,0,0,1-2.973,2.917H4.319A2.9,2.9,0,0,1,1.4,49.807V36.122H.561A.562.562,0,0,1,0,35.561.53.53,0,0,1,.561,35H16.546a.562.562,0,0,1,.561.561.553.553,0,0,1-.561.5Zm-13.181,0V49.807A1.807,1.807,0,0,0,4.319,51.6h8.413a1.82,1.82,0,0,0,1.851-1.795V36.122Z"
                                      transform="translate(0 -33.037)"
                                      fill="#f40000"
                                    ></path>
                                    <path
                                      id="Path_332"
                                      data-name="Path 332"
                                      d="M90,105.561a.562.562,0,0,1,.561-.561.53.53,0,0,1,.561.561v8.75a.53.53,0,0,1-.561.561.562.562,0,0,1-.561-.561Z"
                                      transform="translate(-84.952 -99.111)"
                                      fill="#f40000"
                                    ></path>
                                    <path
                                      id="Path_333"
                                      data-name="Path 333"
                                      d="M195,105.561a.53.53,0,0,1,.561-.561.562.562,0,0,1,.561.561v8.75a.562.562,0,0,1-.561.561.53.53,0,0,1-.561-.561Z"
                                      transform="translate(-184.063 -99.111)"
                                      fill="#f40000"
                                    ></path>
                                    <path
                                      id="Path_334"
                                      data-name="Path 334"
                                      d="M142,105.561a.562.562,0,0,1,.561-.561.53.53,0,0,1,.561.561v8.75a.53.53,0,0,1-.561.561.562.562,0,0,1-.561-.561Z"
                                      transform="translate(-134.036 -99.111)"
                                      fill="#f40000"
                                    ></path>
                                    <path
                                      id="Path_335"
                                      data-name="Path 335"
                                      d="M108.561,1.122a.561.561,0,1,1,0-1.122h3.814a.53.53,0,0,1,.561.561.53.53,0,0,1-.561.561Z"
                                      transform="translate(-101.943)"
                                      fill="#f40000"
                                    ></path>
                                  </g>
                                </g>
                              </svg>
                            </div>
                          </div>
                        ))}
                        <div
                          className={`subTotal ${
                            lang === "ar" ? "header_row_ar" : ""
                          }`}
                        >
                          <span>{t("subTotal")}</span>
                          <span>{productsInState.totalAmount}</span>
                        </div>
                      </div>
                      <div className="buttons">
                        <Link to={"/store/cart"} className="viewCartBtn">
                          {t("buttonReaview")}
                        </Link>
                        <button
                          className="viewCartBtn"
                          onClick={() => dispatch(cartActions.clearCart())}
                        >
                          {t("buttonEmpty")}
                        </button>
                      </div>
                    </>
                  ) : (
                    <h4>Your cart is empty!</h4>
                  )}
                </Popover.Body>
              </Popover>
            }
          >
            <div className="header_logo search">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19.691"
                height="25"
                viewBox="0 0 19.691 25"
                className="fill-white"
              >
                <path
                  id="Path_8965"
                  data-name="Path 8965"
                  d="M32.526,10.38H29.137V9.621a5.667,5.667,0,1,0-11.334,0v.759H14.413a.8.8,0,0,0-.813.813V26.812A2.089,2.089,0,0,0,15.688,28.9H31.2a2.089,2.089,0,0,0,2.088-2.088V11.167A.733.733,0,0,0,32.526,10.38Zm-13.1-.786a4.04,4.04,0,1,1,8.08,0v.759H19.43Zm11.8,17.679H15.715a.486.486,0,0,1-.488-.488V11.98H31.713v14.8A.486.486,0,0,1,31.225,27.273Z"
                  transform="translate(-13.6 -3.9)"
                ></path>
              </svg>
              <span className="numCart">{productsInState.totalQuantity}</span>
            </div>
          </OverlayTrigger>
        </div>
      </div>
    </header>
  );
};

export default Header;
