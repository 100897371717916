import React, { useContext, useState, useEffect } from "react";
import "./ProductDetails.css";
import ProductImageSlider from "ui/product_image_slider/ProductImageSlider";
import Modaly from "ui/modal/Modaly";
import { useDispatch, useSelector } from "react-redux";
import copy from "clipboard-copy";
import { toast } from "react-toastify";
import { Form } from "react-bootstrap";
import { cartActions } from "rtk/slices/CartSlice";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "context/LanguageContext";
import {
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import he from "he"; // A robust HTML entity encoder/decoder library
import { useNavigate } from "react-router-dom"; // Ensure to import useHistory

const ProductDetailsModal = ({
  item,
  show,
  handleClose,
  images,
  decreseItem,
  increseItem,
  AddToCart,
}) => {
  const dispatch = useDispatch();

  const [subProducts, setSubProducts] = useState([]);
  const [productUrl, setProductUrl] = useState("");
  const [baseItemImgUrl, setBaseItemImgUrl] = useState("");
  const [customMessage, setCustomMessage] = useState("");
  const [itemQuantity, setItemQuantity] = useState(0); // Assuming default quantity is 1
  const [subProductQuantities, setSubProductQuantities] = useState({});
  const navigate = useNavigate(); // Use useNavigate instead of useHistory
  const productsInState = useSelector((state) => state.cart);
  const { t } = useTranslation();
  const { lang } = useContext(LanguageContext);

  useEffect(() => {
    if (!item) {
      navigate("/"); // Use navigate for redirection
      return;
    }
    if (item && item.word) {
      try {
        const parsedSubProducts = JSON.parse(item.word);
        const imageUrl =
          item.image.length > 0
            ? `${process.env.REACT_APP_API_URL}${item.image[0].url}`
            : "";
        setBaseItemImgUrl(imageUrl);
        const updatedSubProducts = parsedSubProducts.map((subProduct) => ({
          ...subProduct,
          imageUrl,
        }));
        setSubProducts(updatedSubProducts);
        const initialQuantities = {};
        parsedSubProducts.forEach((_, index) => {
          initialQuantities[index] = 0;
        });
        setSubProductQuantities(initialQuantities);
      } catch (error) {
        console.error("Failed to parse sub-products:", error);
        setSubProducts([]);
      }
    }
  }, [item, navigate]);

  useEffect(() => {
    if (item) {
      const newProductUrl = `${process.env.REACT_APP_BASE_URL}products/${item.id}`;
      setProductUrl(newProductUrl);
    }
  }, [item]);

  const handleCloseModal = () => {
    navigate(-1); // Use navigate for programmatic navigation
    handleClose();
  };
  const generateProductWhatsAppMessage = () => {
    const language = lang; // Use the current language setting
    let message = `${baseItemImgUrl} \n\n`;
    // Add the product URL

    message +=
      language === "ar" ? `*طلب*: ${item.name}\n` : `*Order*: ${item.name}\n`;
    message +=
      language === "ar"
        ? `الكمية: ${itemQuantity}, السعر: ${item.price * itemQuantity} د.ك\n` // Use itemQuantity state
        : `Qty: ${itemQuantity}, Price: ${item.price * itemQuantity} KWD\n`; // Use itemQuantity state

    // Include custom message if provided
    if (customMessage) {
      message +=
        language === "ar"
          ? `\n*عبارة خاصة*: ${customMessage}\n`
          : `\n*Custom Message*: ${customMessage}\n`;
    }

    // Request for confirmation
    message +=
      language === "ar"
        ? `يرجى تأكيد طلبي. شكرًا لك!`
        : `Please confirm my order. Thank you!`;

    return encodeURIComponent(message);
  };

  const handleCopyToClipboard = () => {
    const productUrl = `${process.env.REACT_APP_BASE_URL}products/${item.id}`;
    copy(productUrl);
    toast.success(`copied : ${productUrl}`);
  };

  const handleAddToCart = () => {
    if (itemQuantity === 0) {
      toast.error(t("selectQuantity")); // Use the translated message
      return; // Early return to prevent adding to cart
    }

    const existingItem = productsInState.cartItems.find(
      (it) => it.id === item.id && it.quantity === item.quantity
    );
    if (!existingItem) {
      dispatch(
        cartActions.addItem({
          product: item,
          title: item.title,
          word: customMessage,
          quantity: itemQuantity,
        })
      );
      toast.success("Product Added Successfully.");
    } else {
      toast.info(
        "Item is already in cart. Please increase the quantity if you need more."
      );
    }
  };

  const handleAddSubProductToCart = (subProduct, quantity) => {
    if (quantity === 0) {
      toast.error(t("selectQuantity")); // Use the translated message
      return; // Early return to prevent adding to cart
    }
    // Format the subproduct's name to include the parent product's name
    const formattedSubProductName = `${item.name}(${subProduct.name})`;

    dispatch(
      cartActions.addItem({
        product: { ...subProduct, name: formattedSubProductName }, // Update name here
        quantity,
        isSubProduct: true,
      })
    );
    toast.success(`${subProduct.title} Added Successfully`);
  };

  const handleIncreaseSubProductQuantity = (index) => {
    setSubProductQuantities((prevQuantities) => ({
      ...prevQuantities,
      [index]: (prevQuantities[index] || 0) + 1,
    }));
  };

  const handleDecreaseSubProductQuantity = (index) => {
    setSubProductQuantities((prevQuantities) => ({
      ...prevQuantities,
      [index]: Math.max(1, (prevQuantities[index] || 2) - 1),
    }));
  };

  // Functions to increment and decrement quantity
  const handleIncreaseQuantity = () => {
    setItemQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecreaseQuantity = () => {
    setItemQuantity((prevQuantity) => Math.max(0, prevQuantity - 1)); // Prevents quantity from going below 1
  };
  // Hypothetical scenario where item.description is an array

  const htmlString = he.decode(item.description.join("")); // Join without a comma

  return (
    <Modaly show={show} handleClose={handleCloseModal}>
      <div className="product_modal row detailsRow">
        <div className="col-lg-6 product_swiper">
          {/* Image slider */}
          <ProductImageSlider images={images} />
        </div>
        <div className={`col-lg-6 ${lang === "ar" ? "detailsRow_rtl" : ""}`}>
          {/* Product details and dynamic sub-products */}
          <div className="content_productDetails">
            <h2>{item.name}</h2>
            <div
              style={{ direction: "rtl" }}
              dangerouslySetInnerHTML={{ __html: htmlString }}
            />
            <br></br>
            <div className="itemPriceAndShareBtn">
              <h2>
                {item.price} {t("currency")}
              </h2>
            </div>
          </div>
          <br></br>
          {/* Dynamic sub-products display */}
          {subProducts.length > 0 && (
            <div className="additional_products">
              {/* <h2>يمكنك أيضاً إضافة:</h2> */}
              {subProducts.map((subProduct, index) => (
                <div key={index} className="cardToo">
                  <span>{subProduct.title}</span>
                  <span>{subProduct.description}</span>
                  <span>السعر: {subProduct.price} دك</span>
                  <div className="quantity-selector">
                    <button
                      onClick={() => handleDecreaseSubProductQuantity(index)}
                    >
                      -
                    </button>
                    <span>{subProductQuantities[index]}</span>
                    <button
                      onClick={() => handleIncreaseSubProductQuantity(index)}
                    >
                      +
                    </button>
                  </div>
                  <button
                    className="btn btn-primary add-to-cart-button"
                    onClick={() =>
                      handleAddSubProductToCart(
                        subProduct,
                        subProductQuantities[index]
                      )
                    }
                  >
                    أضف للسلة
                  </button>
                </div>
              ))}
            </div>
          )}

          {/* Add to cart button with quantity adjustment */}
          <div className="IncreaseCart">
            <div className="counterCart">
              <button className="decButton" onClick={handleDecreaseQuantity}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  className="fill-NickelGray"
                >
                  <g
                    id="Icon_ionic-ios-minus-circle-outline"
                    data-name="Icon ionic-ios-minus-circle-outline"
                    transform="translate(-3.375 -3.375)"
                  >
                    <path
                      id="Path_311"
                      data-name="Path 311"
                      d="M24.718,13.774H11.666a1.105,1.105,0,0,0-1.154,1.154,1.117,1.117,0,0,0,1.154,1.154H24.718a1.154,1.154,0,0,0,0-2.308Z"
                      transform="translate(0.183 3.447)"
                    ></path>
                    <path
                      id="Path_312"
                      data-name="Path 312"
                      d="M18.375,5.394a12.976,12.976,0,1,1-9.18,3.8,12.895,12.895,0,0,1,9.18-3.8m0-2.019a15,15,0,1,0,15,15,15,15,0,0,0-15-15Z"
                      transform="translate(0 0)"
                    ></path>
                  </g>
                </svg>
              </button>
              <span>{itemQuantity}</span>
              <button className="incButton" onClick={handleIncreaseQuantity}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  className="fill-defaultThemeColor"
                >
                  <g
                    id="Icon_ionic-ios-add-circle-outline"
                    data-name="Icon ionic-ios-add-circle-outline"
                    transform="translate(-3.375 -3.375)"
                  >
                    <path
                      id="Path_311"
                      data-name="Path 311"
                      d="M24.7,17.031H19.336V11.664a1.153,1.153,0,1,0-2.305,0v5.366H11.664a1.1,1.1,0,0,0-1.153,1.153,1.116,1.116,0,0,0,1.153,1.153h5.366V24.7a1.116,1.116,0,0,0,1.153,1.153A1.147,1.147,0,0,0,19.336,24.7V19.336H24.7a1.153,1.153,0,0,0,0-2.305Z"
                      transform="translate(0.192 0.192)"
                    ></path>
                    <path
                      id="Path_312"
                      data-name="Path 312"
                      d="M18.375,5.394a12.976,12.976,0,1,1-9.18,3.8,12.895,12.895,0,0,1,9.18-3.8m0-2.019a15,15,0,1,0,15,15,15,15,0,0,0-15-15Z"
                      transform="translate(0 0)"
                    ></path>
                  </g>
                </svg>
              </button>
            </div>
            <button
              className="buttonToCart"
              onClick={handleAddToCart}
              type="button"
            >
              <span>
                {item.price} {t("currency")}
              </span>{" "}
              {t("buttonAdd")}
            </button>
          </div>
          {item["words_title"] === "exist" && (
            <>
              <div className="titleProduct">
                <span>يمكنك إضافة عبارة خاصة للطلب</span>
                <Form.Group
                  className="my-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Control
                    type="text"
                    placeholder="إضافة عبارة خاصة"
                    onChange={(e) => setCustomMessage(e.target.value)}
                    required
                  />
                </Form.Group>
              </div>
              <a
                href={`https://wa.me/${
                  process.env.REACT_APP_WHATSAPP_NUMBER
                }?text=${generateProductWhatsAppMessage()}`}
                target="_blank"
                className="buttonToCart contactWhats"
                rel="noreferrer"
              >
                <i className="ri-whatsapp-line"></i>
                <span>
                  {lang === "ar" ? "للطلب عبر الواتساب" : "Order via WhatsApp"}
                </span>
              </a>
            </>
          )}
        </div>
        <div className="shareBtn checkoutcontainer_ar">
          <button className="share">
            <svg
              id="_259-share-arrow"
              data-name="259-share-arrow"
              xmlns="http://www.w3.org/2000/svg"
              width="11.837250000000001"
              height="18"
              viewBox="0 0 10.522 16"
            >
              <path
                id="Path_9530"
                data-name="Path 9530"
                d="M120.222,151.7H110.3a.3.3,0,0,1-.3-.3v-11.16a.3.3,0,0,1,.3-.3h3.9a.3.3,0,1,1,0,.6h-3.6V151.1h9.32V140.541h-3.578a.3.3,0,1,1,0-.6h3.878a.3.3,0,0,1,.3.3V151.4A.3.3,0,0,1,120.222,151.7Z"
                transform="translate(-110 -135.701)"
                fill="#121331"
              ></path>
              <path
                id="Path_9531"
                data-name="Path 9531"
                d="M161.851,58.565a.3.3,0,0,1-.209-.085l-2.482-2.416-2.436,2.414a.3.3,0,1,1-.423-.427l2.646-2.622a.3.3,0,0,1,.421,0l2.694,2.622a.3.3,0,0,1,.006.425A.3.3,0,0,1,161.851,58.565Z"
                transform="translate(-153.896 -55.342)"
                fill="#796b7a"
              ></path>
              <path
                id="Path_9532"
                data-name="Path 9532"
                d="M209.3,66.115a.3.3,0,0,1-.3-.3V55.641a.3.3,0,1,1,.6,0V65.814A.3.3,0,0,1,209.3,66.115Z"
                transform="translate(-204.039 -55.34)"
                fill="#796b7a"
              ></path>
            </svg>
            {t("buttonShare")}
          </button>
        </div>
        <div className={`sharableIcons sharableIconsActive`}>
          <FacebookShareButton url={productUrl} quote={item.name}>
            <svg
              viewBox="0 0 64 64"
              width="36"
              height="36"
              className="border rounded-md"
            >
              <rect
                width="64"
                height="64"
                rx="50"
                ry="50"
                fill="#3b5998"
                style={{ fill: "transparent" }}
              ></rect>
              <path
                d="M34.1,47V33.3h4.6l0.7-5.3h-5.3v-3.4c0-1.5,0.4-2.6,2.6-2.6l2.8,0v-4.8c-0.5-0.1-2.2-0.2-4.1-0.2 c-4.1,0-6.9,2.5-6.9,7V28H24v5.3h4.6V47H34.1z"
                fill="Gray"
              ></path>
            </svg>
          </FacebookShareButton>
          <TwitterShareButton url={productUrl} quote={item.name}>
            <svg
              viewBox="0 0 64 64"
              width="36"
              height="36"
              className="border rounded-md"
            >
              <rect
                width="64"
                height="64"
                rx="50"
                ry="50"
                fill="#00aced"
                style={{ fill: "transparent" }}
              ></rect>
              <path
                d="M48,22.1c-1.2,0.5-2.4,0.9-3.8,1c1.4-0.8,2.4-2.1,2.9-3.6c-1.3,0.8-2.7,1.3-4.2,1.6 C41.7,19.8,40,19,38.2,19c-3.6,0-6.6,2.9-6.6,6.6c0,0.5,0.1,1,0.2,1.5c-5.5-0.3-10.3-2.9-13.5-6.9c-0.6,1-0.9,2.1-0.9,3.3 c0,2.3,1.2,4.3,2.9,5.5c-1.1,0-2.1-0.3-3-0.8c0,0,0,0.1,0,0.1c0,3.2,2.3,5.8,5.3,6.4c-0.6,0.1-1.1,0.2-1.7,0.2c-0.4,0-0.8,0-1.2-0.1 c0.8,2.6,3.3,4.5,6.1,4.6c-2.2,1.8-5.1,2.8-8.2,2.8c-0.5,0-1.1,0-1.6-0.1c2.9,1.9,6.4,2.9,10.1,2.9c12.1,0,18.7-10,18.7-18.7 c0-0.3,0-0.6,0-0.8C46,24.5,47.1,23.4,48,22.1z"
                fill="Gray"
              ></path>
            </svg>
          </TwitterShareButton>
          <WhatsappShareButton url={productUrl} quote={item.name}>
            <svg
              viewBox="0 0 64 64"
              width="36"
              height="36"
              className="border rounded-md"
            >
              <rect
                width="64"
                height="64"
                rx="50"
                ry="50"
                fill="#25D366"
                style={{ fill: "transparent" }}
              ></rect>
              <path
                d="m42.32286,33.93287c-0.5178,-0.2589 -3.04726,-1.49644 -3.52105,-1.66732c-0.4712,-0.17346 -0.81554,-0.2589 -1.15987,0.2589c-0.34175,0.51004 -1.33075,1.66474 -1.63108,2.00648c-0.30032,0.33658 -0.60064,0.36247 -1.11327,0.12945c-0.5178,-0.2589 -2.17994,-0.80259 -4.14759,-2.56312c-1.53269,-1.37217 -2.56312,-3.05503 -2.86603,-3.57283c-0.30033,-0.5178 -0.03366,-0.80259 0.22524,-1.06149c0.23301,-0.23301 0.5178,-0.59547 0.7767,-0.90616c0.25372,-0.31068 0.33657,-0.5178 0.51262,-0.85437c0.17088,-0.36246 0.08544,-0.64725 -0.04402,-0.90615c-0.12945,-0.2589 -1.15987,-2.79613 -1.58964,-3.80584c-0.41424,-1.00971 -0.84142,-0.88027 -1.15987,-0.88027c-0.29773,-0.02588 -0.64208,-0.02588 -0.98382,-0.02588c-0.34693,0 -0.90616,0.12945 -1.37736,0.62136c-0.4712,0.5178 -1.80194,1.76053 -1.80194,4.27186c0,2.51134 1.84596,4.945 2.10227,5.30747c0.2589,0.33657 3.63497,5.51458 8.80262,7.74113c1.23237,0.5178 2.1903,0.82848 2.94111,1.08738c1.23237,0.38836 2.35599,0.33657 3.24402,0.20712c0.99159,-0.15534 3.04985,-1.24272 3.47963,-2.45956c0.44013,-1.21683 0.44013,-2.22654 0.31068,-2.45955c-0.12945,-0.23301 -0.46601,-0.36247 -0.98382,-0.59548m-9.40068,12.84407l-0.02589,0c-3.05503,0 -6.08417,-0.82849 -8.72495,-2.38189l-0.62136,-0.37023l-6.47252,1.68286l1.73463,-6.29129l-0.41424,-0.64725c-1.70875,-2.71846 -2.6149,-5.85116 -2.6149,-9.07706c0,-9.39809 7.68934,-17.06155 17.15993,-17.06155c4.58253,0 8.88029,1.78642 12.11655,5.02268c3.23625,3.21036 5.02267,7.50812 5.02267,12.06476c-0.0078,9.3981 -7.69712,17.06155 -17.14699,17.06155m14.58906,-31.58846c-3.93529,-3.80584 -9.1133,-5.95471 -14.62789,-5.95471c-11.36055,0 -20.60848,9.2065 -20.61625,20.52564c0,3.61684 0.94757,7.14565 2.75211,10.26282l-2.92557,10.63564l10.93337,-2.85309c3.0136,1.63108 6.4052,2.4958 9.85634,2.49839l0.01037,0c11.36574,0 20.61884,-9.2091 20.62403,-20.53082c0,-5.48093 -2.14111,-10.64081 -6.03239,-14.51915"
                fill="Gray"
              ></path>
            </svg>
          </WhatsappShareButton>
          <PinterestShareButton url={productUrl} quote={item.name}>
            <svg
              viewBox="0 0 64 64"
              width="36"
              height="36"
              className="border rounded-md"
            >
              <rect
                width="64"
                height="64"
                rx="50"
                ry="50"
                fill="#cb2128"
                style={{ fill: "transparent" }}
              ></rect>
              <path
                d="M32,16c-8.8,0-16,7.2-16,16c0,6.6,3.9,12.2,9.6,14.7c0-1.1,0-2.5,0.3-3.7 c0.3-1.3,2.1-8.7,2.1-8.7s-0.5-1-0.5-2.5c0-2.4,1.4-4.1,3.1-4.1c1.5,0,2.2,1.1,2.2,2.4c0,1.5-0.9,3.7-1.4,5.7 c-0.4,1.7,0.9,3.1,2.5,3.1c3,0,5.1-3.9,5.1-8.5c0-3.5-2.4-6.1-6.7-6.1c-4.9,0-7.9,3.6-7.9,7.7c0,1.4,0.4,2.4,1.1,3.1 c0.3,0.3,0.3,0.5,0.2,0.9c-0.1,0.3-0.3,1-0.3,1.3c-0.1,0.4-0.4,0.6-0.8,0.4c-2.2-0.9-3.3-3.4-3.3-6.1c0-4.5,3.8-10,11.4-10 c6.1,0,10.1,4.4,10.1,9.2c0,6.3-3.5,11-8.6,11c-1.7,0-3.4-0.9-3.9-2c0,0-0.9,3.7-1.1,4.4c-0.3,1.2-1,2.5-1.6,3.4 c1.4,0.4,3,0.7,4.5,0.7c8.8,0,16-7.2,16-16C48,23.2,40.8,16,32,16z"
                fill="Gray"
              ></path>
            </svg>
          </PinterestShareButton>
          <LinkedinShareButton url={productUrl} quote={item.name}>
            <svg
              viewBox="0 0 64 64"
              width="36"
              height="36"
              className="border rounded-md"
            >
              <rect
                width="64"
                height="64"
                rx="50"
                ry="50"
                fill="#007fb1"
                style={{ fill: "transparent" }}
              ></rect>
              <path
                d="M20.4,44h5.4V26.6h-5.4V44z M23.1,18c-1.7,0-3.1,1.4-3.1,3.1c0,1.7,1.4,3.1,3.1,3.1 c1.7,0,3.1-1.4,3.1-3.1C26.2,19.4,24.8,18,23.1,18z M39.5,26.2c-2.6,0-4.4,1.4-5.1,2.8h-0.1v-2.4h-5.2V44h5.4v-8.6 c0-2.3,0.4-4.5,3.2-4.5c2.8,0,2.8,2.6,2.8,4.6V44H46v-9.5C46,29.8,45,26.2,39.5,26.2z"
                fill="Gray"
              ></path>
            </svg>
          </LinkedinShareButton>
          <button className="shareIcon copy" onClick={handleCopyToClipboard}>
            <svg
              width="20"
              height="20"
              color="Gray"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 14.351 14.35"
            >
              <path
                id="copy-solid"
                d="M10.763,2.691V0H7.624A1.345,1.345,0,0,0,6.278,1.345V9.417a1.345,1.345,0,0,0,1.345,1.345h5.381A1.345,1.345,0,0,0,14.35,9.417V3.588H11.685A.905.905,0,0,1,10.763,2.691ZM11.66,0V2.691H14.35ZM5.381,9.866V3.588H1.345A1.345,1.345,0,0,0,0,4.933v8.072A1.345,1.345,0,0,0,1.345,14.35H6.727a1.345,1.345,0,0,0,1.345-1.345V11.66h-.9A1.8,1.8,0,0,1,5.381,9.866Z"
                fill="gray"
              ></path>
            </svg>
          </button>
        </div>
      </div>
    </Modaly>
  );
};

export default ProductDetailsModal;
