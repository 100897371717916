import React, { useContext, useEffect, useState } from "react";
import "./CartSection.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "rtk/slices/CartSlice";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "context/LanguageContext";
import { Form, Collapse, Button, InputGroup } from "react-bootstrap";

const CartSection = () => {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const productsInState = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const { lang, selectedAreas } = useContext(LanguageContext);
  const [fees, setFees] = useState(2.5);
  const { t } = useTranslation();

  useEffect(() => {
    setFees(calculateFees());
  }, [selectedAreas]);
  // Utility function to format image URLs correctly
  const formatImageUrl = (imageUrl) => {
    const baseUrl = process.env.REACT_APP_API_URL;
    if (!imageUrl) return "";
    // Check if the imageUrl already includes the baseUrl
    if (imageUrl.startsWith(baseUrl)) {
      return imageUrl;
    }
    // Check if imageUrl starts with "http" which means it's a complete URL
    if (imageUrl.startsWith("http")) {
      return imageUrl;
    }
    // Otherwise, concatenate the baseUrl and the imageUrl
    return `${baseUrl}${imageUrl}`;
  };
  // Function to calculate fees based on selected areas
  const calculateFees = () => {
    return selectedAreas === "97" ? 3.5 : 2.5;
  };
  const handleTermsChange = () => {
    setTermsAccepted(!termsAccepted);
  };

  const decreseItem = (item) => {
    dispatch(cartActions.decreseItem(item.id));
  };

  const increseItem = (item) => {
    dispatch(cartActions.addItem({ product: item }));
  };

  return (
    <section
      className={`Cart_section ${lang === "ar" ? "checkoutcontainer_ar" : ""}`}
    >
      <div className="headCartSection">
        <h2 className="pt-4 pb-3">{t("Cart")}</h2>
        <Link to={"/store"}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            aria-hidden="true"
            className="w-4 h-4 inline ltr:mr-2 rtl:ml-2 rtl:rotate-180"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M7 16l-4-4m0 0l4-4m-4 4h18"
            ></path>
          </svg>
          {t("continue")}
        </Link>
      </div>
      <div className="productsCart">
        <div className="productsCart_cards">
          {productsInState.cartItems.map((item) => (
            <div className="productsCart_cards_card" key={item.id}>
              <div className="cartImg">
                <img
                  src={formatImageUrl(
                    item.imageUrl || item.image?.[0]?.url || ""
                  )}
                  alt="cardImg"
                />
              </div>

              <div className="cardContent">
                <div className="cardText">
                  {/* Inline log */}
                  <h4>{item.name}</h4>
                  <p className="price">
                    {item.price} {t("currency")}
                  </p>
                </div>
                <div className="countity">
                  <div className="counterCart">
                    <button onClick={() => decreseItem(item)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                        color="black"
                        width="20"
                        height="20"
                        className="hover:bg-black hover:bg-opacity-10 p-1 rounded-full transition-all"
                      >
                        <path
                          fillRule="evenodd"
                          d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                    <span>{item.quantity}</span>
                    <button onClick={() => increseItem(item)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                        color="black"
                        width="20"
                        height="20"
                        className="hover:bg-black hover:bg-opacity-10 p-1 rounded-full transition-all"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </div>
                  <div
                    className="removeFrom"
                    onClick={() => dispatch(cartActions.deleteItem(item.id))}
                  >
                    <svg
                      className="h-5 w-5  ltr:mr-1 rtl:ml-1"
                      xmlns="http://www.w3.org/2000/svg"
                      width="17.107"
                      height="19.687"
                      viewBox="0 0 17.107 19.687"
                    >
                      <g
                        id="Group_9777"
                        data-name="Group 9777"
                        transform="translate(-3708.868 -2157.542)"
                      >
                        <g
                          id="Group_650"
                          data-name="Group 650"
                          transform="translate(3708.868 2157.542)"
                        >
                          <path
                            id="Path_331"
                            data-name="Path 331"
                            d="M15.7,36.122V49.807a2.945,2.945,0,0,1-2.973,2.917H4.319A2.9,2.9,0,0,1,1.4,49.807V36.122H.561A.562.562,0,0,1,0,35.561.53.53,0,0,1,.561,35H16.546a.562.562,0,0,1,.561.561.553.553,0,0,1-.561.5Zm-13.181,0V49.807A1.807,1.807,0,0,0,4.319,51.6h8.413a1.82,1.82,0,0,0,1.851-1.795V36.122Z"
                            transform="translate(0 -33.037)"
                            fill="#f40000"
                          ></path>
                          <path
                            id="Path_332"
                            data-name="Path 332"
                            d="M90,105.561a.562.562,0,0,1,.561-.561.53.53,0,0,1,.561.561v8.75a.53.53,0,0,1-.561.561.562.562,0,0,1-.561-.561Z"
                            transform="translate(-84.952 -99.111)"
                            fill="#f40000"
                          ></path>
                          <path
                            id="Path_333"
                            data-name="Path 333"
                            d="M195,105.561a.53.53,0,0,1,.561-.561.562.562,0,0,1,.561.561v8.75a.562.562,0,0,1-.561.561.53.53,0,0,1-.561-.561Z"
                            transform="translate(-184.063 -99.111)"
                            fill="#f40000"
                          ></path>
                          <path
                            id="Path_334"
                            data-name="Path 334"
                            d="M142,105.561a.562.562,0,0,1,.561-.561.53.53,0,0,1,.561.561v8.75a.53.53,0,0,1-.561.561.562.562,0,0,1-.561-.561Z"
                            transform="translate(-134.036 -99.111)"
                            fill="#f40000"
                          ></path>
                          <path
                            id="Path_335"
                            data-name="Path 335"
                            d="M108.561,1.122a.561.561,0,1,1,0-1.122h3.814a.53.53,0,0,1,.561.561.53.53,0,0,1-.561.561Z"
                            transform="translate(-101.943)"
                            fill="#f40000"
                          ></path>
                        </g>
                      </g>
                    </svg>
                    remove
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="totallyPrices">
          <div className="cartTotal">
            <span>{t("CartTotal")}</span>
            <span>
              {productsInState.totalAmount} {t("currency")}
            </span>
          </div>
          <div className="cartTotal">
            <span>{t("CartFees")}</span>
            <span>
              {selectedAreas === "97"
                ? `3.500 ${t("currency")}`
                : `2.500 ${t("currency")}`}
            </span>
          </div>
        </div>
        <div className="total">
          <span>{t("Total")}</span>
          <span>
            {`${productsInState.totalAmount + fees}`} {t("currency")}
          </span>
        </div>
      </div>
      {productsInState.cartItems.length > 0 && ( // Conditionally render the fees section if there are items in the cart
        <>
          <div className="totallyPrices">
            {/* Cart total */}
            <div className="cartTotal">
              <span>{t("CartTotal")}</span>
              <span>
                {productsInState.totalAmount} {t("currency")}
              </span>
            </div>
            {/* Delivery fees */}
            <div className="cartFees">
              <span>{t("CartFees")}</span>
              <span>
                {fees} {t("currency")}
              </span>
            </div>
          </div>
          <div className="total">
            {/* Total calculation including fees */}
            <span>{t("Total")}</span>
            <span>
              {(productsInState.totalAmount + fees).toFixed(2)} {t("currency")}
            </span>
          </div>
        </>
      )}

      <div className="applyCoupon">
        <Form.Group controlId="termsCheckbox" className=" checkCollapse">
          <div
            variant="primary" // Customize the button variant as needed
            className="checkForm applyCopon"
            onClick={handleTermsChange}
          >
            <span>{t("ApplyCoupon")}</span>
            <i className="ri-arrow-down-s-line"></i>
          </div>
          <Collapse in={termsAccepted} className="collapseChech">
            <div>
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder={`${t("EnterCoupon")}`}
                  aria-label="Enter Coupon"
                  aria-describedby="basic-addon2"
                />
                <Button
                  variant="success"
                  id="button-addon2"
                  style={{ borderRadius: "10px 0 0 10px" }}
                  className={`lng ${lang === "en" ? "d-none" : ""}`}
                >
                  {t("Apply")}
                </Button>
                <Button
                  variant="success"
                  id="button-addon2"
                  style={{ borderRadius: "0 10px 10px 0" }}
                  className={`lng ${lang === "ar" ? "d-none" : ""}`}
                >
                  {t("Apply")}
                </Button>
              </InputGroup>
            </div>
          </Collapse>
        </Form.Group>
      </div>
    </section>
  );
};

export default CartSection;
