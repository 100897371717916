import React, { useState } from 'react'
import './ModalNote.css'
import { Button, Modal } from 'react-bootstrap';
const ModalNote = ({show, onHide,confirmNote}) => {
    const [text, setText] = useState('');
    const maxLength = 200;
  
    const handleChange = (event) => {
      let newText = event.target.value;
  
      // Check if the length exceeds the maximum limit
      if (newText.length <= maxLength) {
        setText(newText);
      }
    }
  return (
    <Modal
    show={show}
    onHide={onHide}
    size="md"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    backdrop="static"
    keyboard={false}
    className="modalCalendar"
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
        Special request
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="NoteText">
        <div>
          <textarea value={text}
        onChange={handleChange} maxLength={maxLength} className='textAreaModal' placeholder='write your request here'/>
              <p>Remaining characters: {maxLength - text.length}</p>
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button className="modalDateBtn w-100"
       onClick={()=>{
        confirmNote(text);
        onHide();
        }
        }
        >confirm</Button>
    </Modal.Footer>
  </Modal>
  )
}

export default ModalNote