import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const fetchProducts = createAsyncThunk(
  "ProductSlice/fetchProducts",
  async () => {
    const res = await fetch(`${process.env.REACT_APP_API_URL}api/products`);
    const data = await res.json();
    return data;
  }
);

const initialState = {
  data: [],
  loading: false,
};

const ProductSlice = createSlice({
  initialState,
  name: "ProductSlice",
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(fetchProducts.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(fetchProducts.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });

    builder.addCase(fetchProducts.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
});

// eslint-disable-next-line no-empty-pattern
export const {} = ProductSlice.actions;
export default ProductSlice.reducer;
