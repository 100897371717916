import CommonSection from "components/common_section/CommonSection";
import React, { useContext, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Helmet from "ui/helmet/Helmet";
import commonImgCat from "assets/images/mainImg.jpeg";
import "./Home.css";
import { Link, Link as LinkRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";

import commonRadious from "assets/images/brown.png";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "context/LanguageContext";

const Home = () => {
  const categories = useSelector((state) => state.categories.data.data);
  const loadingCategories = useSelector((state) => state.categories.loading);
  const productsInState = useSelector((state) => state.cart);

  const [scrolledbtm, setScrolledbtm] = useState(false);

  useEffect(() => {
    window.scrollY > 60 ? setScrolledbtm(true) : setScrolledbtm(false);
    window.addEventListener("scroll", () => {
      window.scrollY > 60 ? setScrolledbtm(true) : setScrolledbtm(false);
    });
  }, []);
  const handleBackToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const { t } = useTranslation();
  const { lang } = useContext(LanguageContext);

  // Reverse the categories for display without mutating the original array
  const displayedCategories = Array.isArray(categories)
    ? [...categories].reverse()
    : [];

  return (
    <>
      <Helmet title="Home" />
      <main>
        <Row className={`rw ${lang === "ar" ? "rw_ar" : ""}`}>
          <Col lg="4" className="comonL">
            <div className="headerCommonL stickyDiv">
              <div className="headSide">
                <Link to={"/"} className="linktohome">
                  <i className="ri-arrow-left-line"></i>
                </Link>
                <img src={commonRadious} alt="headSide" />
                <span>Sweet_A2Z Company</span>
              </div>
            </div>
            <div className="catgs px-4">
              {!loadingCategories &&
                displayedCategories.length > 0 &&
                displayedCategories.map((category) => (
                  <div className="catg" key={category.id}>
                    <LinkRouter to={`/store/category/${category.id}`}>
                      {category.image[0]?.url ? (
                        <img
                          src={`${process.env.REACT_APP_API_URL}${category.image[0]?.url}`}
                          alt="categoryImg1"
                        />
                      ) : (
                        <img src={commonImgCat} alt="categoryImg2" />
                      )}
                    </LinkRouter>
                    <h3>{category.name}</h3>
                  </div>
                ))}
            </div>
            <button
              onClick={handleBackToTop}
              className={`backToTop ${scrolledbtm ? "backToTopActive" : ""}`}
            >
              <i className="ri-arrow-up-line"></i>
            </button>
            <div className="cartBtnn">
              <LinkRouter to={"/store/cart"} className="reviewCartBtn">
                <div className="numCartPrice">
                  <span className="numproductcart">
                    {productsInState.totalQuantity}
                  </span>
                  <span className="numCartPrice_price">
                    {productsInState.totalAmount} {t("currency")}
                  </span>
                </div>
                {/* <span className="ReviewCart">REVIEW CART</span> */}
                <span className="ReviewCart">{t("buttonReaview")}</span>
              </LinkRouter>
            </div>
          </Col>
          <Col lg="8" className={`comon ${lang === "ar" ? "comon_ar" : ""}`}>
            <CommonSection />
          </Col>
        </Row>
      </main>
    </>
  );
};

export default Home;
