import CommonSection from "components/common_section/CommonSection";
import React from "react";
import { Col, Row } from "react-bootstrap";
import Helmet from "ui/helmet/Helmet";
import "./Search.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { cartActions } from "rtk/slices/CartSlice";
import { toast } from "react-toastify";
import ProductCard from "components/ProductCard/ProductCard";
import ProductDetailsModal from "components/productDetails/ProductDetailsModal";
const Search = () => {
  const categories = useSelector((state) => state.categories.data.data);
  const loadingCategories = useSelector((state) => state.categories.loading);

  const products = useSelector((state) => state.products.data.data);

  //
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const [images, setImages] = useState([]);
  const [quantityProduct, setQuantityProduct] = useState([]);

  const addHttpsToUrl = (url) => `${process.env.REACT_APP_API_URL}${url}`;

  const handleshowtest = (item) => {
    setSelectedItem(item);
    setShowModal(true);
    const productM = products.find((itemp) => itemp.product.id === item.id);
    setQuantityProduct(
      productsInState.cartItems.find((it) => it.id === productM.product.id)
        ?.quantity
    );
    const updatedImages = productM.images?.map((image) => ({
      original: addHttpsToUrl(image.original),
      thumbnail: addHttpsToUrl(image.thumbnail),
    }));
    setImages(updatedImages);
  };

  const handleClosetest = () => {
    setShowModal(false);
  };

  //
  const dispatch = useDispatch();
  const AddToCart = (product) => {
    dispatch(cartActions.addItem({ product: product }));
    toast.success("Product Added Successfully");
  };
  const productsInState = useSelector((state) => state.cart);
  const decreseItem = (item) => {
    dispatch(cartActions.decreseItem(item.id));
    setQuantityProduct(
      productsInState.cartItems.find((it) => it.id === item.id)?.quantity
    );
  };

  const increseItem = (item) => {
    dispatch(cartActions.addItem({ product: item }));
    setQuantityProduct(
      productsInState.cartItems.find((it) => it.id === item.id)?.quantity
    );
  };

  // Filter products based on search term
  const [searchTerm, setSearchTerm] = useState("");
  const filteredCategories = categories.filter((category) =>
    category.products.some((product) =>
      product.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1); // Go back one step in the history
  };
  return (
    <>
      <Helmet title="Search" />
      <Row className="rw">
        <Col lg="4" className="comonL">
          <div className="headSide">
            <Link onClick={goBack} className="linktohome">
              <i className="ri-arrow-left-line"></i>
            </Link>
            <div className="inputSearch">
              <i className="ri-search-line"></i>
              <input
                type="text"
                placeholder="Search products"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
          <div className="cardProductSearch">
            {!loadingCategories &&
              filteredCategories.length > 0 &&
              filteredCategories.map((category) => (
                <div
                  key={category.id}
                  className="bodyProducts"
                  id={`${category.id}`}
                >
                  <h3>{category.name}</h3>

                  {category.products
                    .filter((product) =>
                      product.name
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    )
                    .map((item) => (
                      <ProductCard
                        key={item.id}
                        item={item}
                        handleShow={() => handleshowtest(item)}
                        AddToCart={AddToCart}
                      />
                    ))}
                </div>
              ))}
            {selectedItem && (
              <ProductDetailsModal
                item={selectedItem}
                show={showModal}
                handleClose={handleClosetest}
                images={images} // Adjust this based on your item structure
                quantityProduct={quantityProduct}
                decreseItem={() => decreseItem(selectedItem)} // Make sure to define these functions
                increseItem={() => increseItem(selectedItem)} // Make sure to define these functions
                AddToCart={() => {
                  AddToCart(selectedItem);
                }}
              />
            )}
            <div className="cartBtnn">
              <Link to={"/store/cart"} className="reviewCartBtn">
                <div className="numCartPrice">
                  <span className="numproductcart">
                    {productsInState.totalQuantity}
                  </span>
                  <span className="numCartPrice_price">
                    {productsInState.totalAmount} KWD
                  </span>
                </div>
                <span className="ReviewCart">REVIEW CART</span>
              </Link>
            </div>
          </div>
          {/* <div className='textSearch'>
                What are you searching for?
            </div> */}
        </Col>
        <Col lg="8" className="comon">
          <CommonSection />
        </Col>
      </Row>
    </>
  );
};

export default Search;
