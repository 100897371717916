import React, { useContext } from 'react'
import ReactDOM  from 'react-dom';
import { Modal } from 'react-bootstrap';
import { LanguageContext } from 'context/LanguageContext';
import './ModalRegister.css'
import commonImg from 'assets/images/brown.png'



const ModalRegister = (props) => {
    const {lang} = useContext(LanguageContext);
    if(!props.show){
        return null
    }
    return ReactDOM.createPortal(
        <Modal
            show={props.show}
            onHide={props.handleClose}
            dialogClassName={`custom-modal-Register ${lang === 'ar' ? 'custom-modal-Register_ar' : ''}`}
            animation={true}
            keyboard={false}
          >
            <Modal.Header closeButton>
            <img src={commonImg} alt='headSide' className='imgReg'/>
              {/* <Modal.Title>{props.title}</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              {props.children}
            </Modal.Body>
          </Modal>,
        document.querySelector('#modal-root')
      )
}

export default ModalRegister;

