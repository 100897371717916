import {configureStore} from '@reduxjs/toolkit'
import CartSlice from './slices/CartSlice';
import CategorySlice from './slices/CategorySlice';
import ProductSlice from './slices/ProductSlice';

export const store = configureStore({
    reducer:{
        cart: CartSlice,
        categories: CategorySlice,
        products: ProductSlice,
    }
});