import React from "react";
import { Link } from "react-router-dom";
import brown from "assets/images/brown.png";
import whats from "assets/images/whats.png";
import insta from "assets/images/insta.png";
import snap from "assets/images/snap.png";
import tiktok from "assets/images/tiktok.png";
import telegram from "assets/images/telegram.png";
import "./Social.css";
import Helmet from "ui/helmet/Helmet";
const Social = () => {
  return (
    <>
      <Helmet title="a2z" />
      <div className="body">
        <div className="mainn">
          <div className="main container">
            <div className="head text-center">
              <img src={brown} alt="" />
              <h1>Sweet Bakery</h1>
              <p>The House Of Sweets</p>
            </div>
            <div className="container">
              <div className="row-reverse w-100">
                <div className="col-lg-12 text-center btnn my-2">
                  <Link to="/store">
                    <i className="ri-shopping-cart-fill"></i>
                    <span>Order Now</span>
                  </Link>
                </div>
                <div className="col-lg-12 text-center btnn my-2 justify-content-between">
                  <a href="https://wa.me/96565557780" target="blank">
                    <img src={whats} alt="" />
                    <span>Whatsapp</span>
                  </a>
                </div>
                <div className="col-lg-12 text-center btnn my-2">
                  <a
                    href="https://instagram.com/sweet_a2z?igshid=MzRlODBiNWFlZA=="
                    target="blank"
                  >
                    <img src={insta} alt="" />
                    <span>Instagram</span>
                  </a>
                </div>
                <div className="col-lg-12 text-center btnn my-2">
                  <a href="https://t.snapchat.com/wofdhdlJ" target="blank">
                    <img src={snap} alt="" />
                    <span>Snapchat</span>
                  </a>
                </div>
                <div className="col-lg-12 text-center btnn my-2">
                  <a
                    href="https://www.tiktok.com/@sweet_a2z?_t=8fcdxfQRtQ2&_r=1"
                    target="blank"
                  >
                    <img src={tiktok} alt="" />
                    <span>Tiktok</span>
                  </a>
                </div>
                <div className="col-lg-12 text-center btnn my-2">
                  <a
                    href="https://x.com/sweet_a2z?s=11&t=MHqiOIrfNylbbvQWoFbtSA"
                    target="blank"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                    >
                      <path
                        d="M22.9079 2.37988H27.1247L17.9121 12.9092L28.75 27.2373H20.264L13.6175 18.5474L6.01243 27.2373H1.79304L11.6468 15.975L1.25 2.37988H9.95139L15.9592 10.3228L22.9079 2.37988ZM21.4279 24.7134H23.7645L8.68174 4.7713H6.17433L21.4279 24.7134Z"
                        fill="white"
                      />
                    </svg>
                    <span>Twitter x</span>
                  </a>
                </div>
                <div className="col-lg-12 text-center btnn my-2">
                  <a href="#">
                    <img src={telegram} alt="" />
                    <span>Telegram</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Social;
