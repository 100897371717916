import Home from "pages/Home/Home";
import Cart from "pages/cart/Cart";
import Products from "pages/products/Products";
import Login from "pages/register/Login";
import SignUp from "pages/register/SignUp";
import Search from "pages/search/Search";
import Social from "pages/social/Social";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { fetchCategories } from "rtk/slices/CategorySlice";
import { fetchProducts } from "rtk/slices/ProductSlice";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchProducts());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  return (
    <Routes>
      <Route path="/" element={<Social />} />
      <Route path="/store" element={<Home />} />
      <Route path="/store/category/:1" element={<Products />} />

      <Route path="/store/products/:productId" element={<Products />} />

      <Route path="/store/search" element={<Search />} />
      <Route path="/store/cart" element={<Cart />} />
      <Route path="/store/signup" element={<SignUp />} />
      <Route path="/store/login" element={<Login />} />
    </Routes>
  );
}

export default App;
