import React from "react";
import "./CommonSection.css";
import Header from "components/layouts/header/Header";
import commonRadious from "assets/images/brown.png";
import { useContext } from "react";
import { LanguageContext } from "context/LanguageContext";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import placeholderImg from "assets/images/mainImg.jpeg"; // Replace with your placeholder image

const CommonSection = () => {
  const { images } = useContext(LanguageContext);

  const settings = {
    dots: false, // Enable dots navigation
    infinite: true, // Enable continuous looping
    autoplay: true, // Enable autoplay (optional)
    autoplaySpeed: 4000, // Autoplay transition speed (optional)
    slidesToShow: 1, // Display one image at a time
    slidesToScroll: 1, // Transition one image at a time
    arrows: false, // Disable default arrows (optional)
    fade: true, // Enable fade transition
    speed: 500,
    // You can customize other settings like speed, fade, etc.
  };

  return (
    <>
      <Header />
      <section className="common_section">
        <div className="bgImg">
          <Slider {...settings}>
            {images &&
              images.length > 0 &&
              images.map((image, index) => (
                <div key={index} className="carousel-item">
                  <img
                    src={`${process.env.REACT_APP_API_URL}${image.original}`}
                    alt={`ImageOriginal ${index}`}
                    className="commonImg w-100"
                    onError={(e) => {
                      e.target.src = placeholderImg; // Replace with error image if needed
                    }} // Handle failed image loading
                  />
                </div>
              ))}
          </Slider>
        </div>
        <div className="commonTitle">
          <img src={commonRadious} alt="imgRadious" />
          <h1 className="pt-2">Sweet_A2Z Company</h1>
        </div>
      </section>
    </>
  );
};

export default CommonSection;
