import { createSlice } from "@reduxjs/toolkit";

const storedItems = localStorage.getItem("sweetCart")
  ? JSON.parse(localStorage.getItem("sweetCart"))
  : {
      cartItems: [],
      totalAmount: 0,
      totalQuantity: 0,
    };
const initialState = {
  cartItems: storedItems.cartItems,
  totalAmount: storedItems.totalAmount,
  totalQuantity: storedItems.totalQuantity,
};

const cartSlice = createSlice({
  name: "cartSlice",
  initialState,
  reducers: {
    addItem: (state, action) => {
      const { product, quantity = 1, isSubProduct = false } = action.payload;

      // Determine if the product is a main product or a sub-product
      const itemId = isSubProduct
        ? `${product.productId}-${product.title}`
        : product.id;

      const existingItem = state.cartItems.find(
        (item) => item.itemId === itemId
      );

      if (!existingItem) {
        // Adding a new item to the cart
        const newItem = {
          ...product,
          itemId, // Unique identifier for each item in the cart
          quantity,
          totalPrice: product.price * quantity,
        };

        if (isSubProduct) {
          newItem.productId = product.productId; // Keep track of the parent product ID
        }

        state.cartItems.push(newItem);
        state.totalQuantity += quantity;
      } else {
        // Item already exists, update its quantity and total price
        existingItem.quantity += quantity;
        existingItem.totalPrice += product.price * quantity;
        state.totalQuantity += quantity;
      }

      // Update total cart amount
      state.totalAmount = state.cartItems.reduce(
        (acc, item) => acc + item.totalPrice,
        0
      );

      // Sync with localStorage
      localStorage.setItem(
        "sweetCart",
        JSON.stringify({
          cartItems: state.cartItems,
          totalAmount: state.totalAmount,
          totalQuantity: state.totalQuantity,
        })
      );
    },

    deleteItem: (state, action) => {
      const id = action.payload;
      const existingItem = state.cartItems.find((item) => item.id === id);

      if (existingItem) {
        state.cartItems = state.cartItems.filter((item) => item.id !== id);
        state.totalQuantity = state.totalQuantity - existingItem.quantity;
      }
      state.totalAmount = state.cartItems.reduce((acc, item) => {
        acc += Number(item.price) * Number(item.quantity);
        return acc;
      }, 0);
      localStorage.setItem(
        "sweetCart",
        JSON.stringify({
          cartItems: state.cartItems,
          totalAmount: state.totalAmount,
          totalQuantity: state.totalQuantity,
        })
      );
    },

    decreseItem: (state, action) => {
      const id = action.payload;
      const existingItem = state.cartItems.find((item) => item.id === id);

      if (existingItem && existingItem.quantity > 1) {
        existingItem.quantity--;
        state.totalQuantity--;
      }
      state.totalAmount = state.cartItems.reduce((acc, item) => {
        acc += Number(item.price) * Number(item.quantity);
        return acc;
      }, 0);
      localStorage.setItem(
        "sweetCart",
        JSON.stringify({
          cartItems: state.cartItems,
          totalAmount: state.totalAmount,
          totalQuantity: state.totalQuantity,
        })
      );
    },
    clearCart: (state, action) => {
      state.cartItems = [];
      state.totalAmount = 0;
      state.totalQuantity = 0;
      localStorage.setItem(
        "sweetCart",
        JSON.stringify({ cartItems: [], totalAmount: 0, totalQuantity: 0 })
      );
    },
  },
});

export const cartActions = cartSlice.actions;
export default cartSlice.reducer;
