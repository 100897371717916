import React from 'react';
import ReactDOM  from 'react-dom';
import './Modal.css';
import { Modal } from 'react-bootstrap';


const Modaly = (props) => {
    if(!props.show){
        return null
    }
  return ReactDOM.createPortal(
    <Modal
        show={props.show}
        onHide={props.handleClose}
        dialogClassName="custom-modal"
        animation={true}
        backdrop="static"
        keyboard={false}
      >
        <div onClick={props.handleClose} className='closeIcon'><i className="ri-close-line"></i></div>
        <Modal.Body>
          {props.children}
        </Modal.Body>
      </Modal>,
    document.querySelector('#modal-root')
  )
}

export default Modaly