import React from 'react';
import { Button, Container, Modal } from 'react-bootstrap';
import Calendar from 'react-calendar';
import './calendarModal.css';
// import { useState } from 'react';
import { toast } from 'react-toastify';
// import TimePicker from 'react-time-picker';
// import 'react-time-picker/dist/TimePicker.css';
// import 'react-clock/dist/Clock.css';

const MyVerticallyCenteredModal = React.memo(
  ({
    show,
    onHide,
    selecteddate,
    handledatechange,
    data,
    handletimechange,
  }) => {
    // const [selectedTime, setSelectedTime] = useState(null); // Maintain the selected time
    // const handleTimeClass = (time) => {
    //     setSelectedTime(time);
    //   };
    const confirmCalendar = () => {
      toast.success('your date successfully selected');
    };
    const [value, setValue] = React.useState('12:00:AM');
    // console.log(value);
    const generateOptions = (start, end) => {
      const options = [];
      for (let i = start; i <= end; i++) {
        options.push(i.toString().padStart(2, '0'));
      }
      return options;
    };

    const hours = generateOptions(1, 12);
    const minutes = generateOptions(0, 59);
    const ampmOptions = ['AM', 'PM'];

    const onChange = (newTime) => {
      setValue(newTime);
    };
    const handleHourChange = (e) => {
      const newHour = e.target.value;
      const newTime = `${newHour}:${value.split(':')[1]}`;
      onChange(newTime);
    };

    const handleMinuteChange = (e) => {
      const newMinute = e.target.value;
      const newTime = `${value.split(':')[0]}:${newMinute}`;
      onChange(newTime);
    };
    const handleAmPmChange = (e) => {
      const newAmPm = e.target.value;
      const newTime = `${value.split(':')[0]}:${
        value.split(':')[1]
      }:${newAmPm}`;
      onChange(newTime);
    };

    return (
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        className="modalCalendar"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Schedule Delivery
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="calendarr">
            <div>
              <Calendar
                onChange={handledatechange}
                value={selecteddate}
                activeStartDate={new Date()}
                minDate={new Date(2000, 0, 1)} // January 1, 2000
                maxDate={new Date(2100, 11, 31)} // December 31, 2100
              />
            </div>
            <div className="selectDeliveryTime py-3">
              <h2>Select Delivery Time</h2>
              {/* <Container>
                <div className='timesToSelect'>
                    {
                        data?.map((time)=>(
                            <div className={`selectTimeCard ms-3 my-2 ${selectedTime && selectedTime.id === time.id ? 'selectedTimeActive' : ''}`} key={time.id} onClick={()=> {
                                handletimechange(time) ;
                                handleTimeClass(time)
                                }
                            }>
                                {time.start_time} - {time.end_time} 
                            </div>
                        ))
                    }
                </div>
              </Container> */}
              <Container>
                <div className="timesToSelect">
                  <select
                    defaultValue={value.split(':')[0]}
                    onChange={handleHourChange}
                  >
                    {hours.map((hour) => (
                      <option key={hour} value={hour}>
                        {hour}
                      </option>
                    ))}
                  </select>

                  <select
                    defaultValue={value.split(':')[1]}
                    onChange={handleMinuteChange}
                  >
                    {minutes.map((minute) => (
                      <option key={minute} value={minute}>
                        {minute}
                      </option>
                    ))}
                  </select>
                  <select
                    defaultValue={value.split(':')[2] || 'AM'}
                    onChange={handleAmPmChange}
                  >
                    {ampmOptions.map((ampm) => (
                      <option key={ampm} value={ampm}>
                        {ampm}
                      </option>
                    ))}
                  </select>
                </div>
              </Container>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="modalDateBtn w-100"
            onClick={() => {
              confirmCalendar();
              onHide();
              handletimechange(value);
            }}
          >
            confirm
          </Button>
        </Modal.Footer>
      </Modal>
    );
  },
);

export default MyVerticallyCenteredModal;
