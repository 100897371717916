import React, { useContext } from 'react'
import ReactDOM  from 'react-dom';
import './ModalCategory.css'
import { Modal } from 'react-bootstrap';
import { LanguageContext } from 'context/LanguageContext';
const ModalCategory = (props) => {
  const {lang} = useContext(LanguageContext);
    if(!props.show){
        return null
    }
  return ReactDOM.createPortal(
    <Modal
        show={props.show}
        onHide={props.handleClose}
        dialogClassName={`custom-modal-category ${lang === 'ar' ? 'custom-modal-category_ar' : ''}`}
        animation={true}
        // backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.children}
        </Modal.Body>
      </Modal>,
    document.querySelector('#modal-root')
  )
}

export default ModalCategory