import React from "react";
import ImageGallery from "react-image-gallery";
import "./ProductImageSlider.css"; // Make sure this path is correct
import "react-image-gallery/styles/css/image-gallery.css";

const ProductImageSlider = ({ images }) => {
  return (
    <ImageGallery
      items={images}
      showBullets={true} // Display pagination dots
      showIndex={true} // Display current image index
      showPlayButton={false} // Hide play/pause button (optional)
      lazyLoad={true} // Enable lazy loading for improved performance
      showFullscreenButton={true} // Display fullscreen button
      useBrowserFullscreen={true} // Leverage browser's native fullscreen
      showThumbnails={true} // Display image thumbnails
      thumbnailPosition="bottom" // Adjust thumbnail position if needed
      additionalClass="custom-gallery" // Custom class for targeted styling
      infinite={false} // Disable infinite looping (optional)
      
    />
  );
};

export default ProductImageSlider;
