import CommonSection from "components/common_section/CommonSection";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Helmet from "ui/helmet/Helmet";
import commonImg from "assets/images/brown.png";
import "./Products.css";
import { Link, NavLink, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "rtk/slices/CartSlice";
import { toast } from "react-toastify";
import ModalCategory from "ui/modalCategory/ModalCategory";
import ProductCard from "components/ProductCard/ProductCard";
import ProductDetailsModal from "components/productDetails/ProductDetailsModal";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "context/LanguageContext";

const Products = () => {
  const id = useParams();

  const categories = useSelector((state) => state.categories.data?.data || []);
  const loadingCategories = useSelector((state) => state.categories.loading);

  const products = useSelector((state) => state.products.data?.data || []);

  const categoryParams =
    !loadingCategories &&
    categories?.find((c) => Number(c.id) === Number(id[1]));

  // Assuming categoryParams.products is an array of objects with a 'priority' field
  const displayedProducts =
    categoryParams && Array.isArray(categoryParams.products)
      ? [...categoryParams.products].sort((a, b) => a.priority - b.priority)
      : [];

  // Reverse the categories for the horizontal scroll
  const displayedCategories = Array.isArray(categories)
    ? [...categories].sort((a, b) => a.priority - b.priority)
    : [];

  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const [images, setImages] = useState([]);
  const [quantityProduct, setQuantityProduct] = useState([]);

  useEffect(() => {
    const productId = id.productId;
    // Ensure this part is corrected based on the revised useParams usage
    if (productId && categories) {
      const category = categories.find((cat) =>
        cat.products?.some((product) => product.id === Number(productId))
      );
      const product = category?.products.find(
        (product) => product.id === Number(productId)
      );
      if (product) {
        handleshowtest(category, product);
      }
    }
  }, [id, categories]);

  useEffect(() => {
    window.scrollY > 60 ? setScrolledbtm(true) : setScrolledbtm(false);
    window.addEventListener("scroll", () => {
      window.scrollY > 60 ? setScrolledbtm(true) : setScrolledbtm(false);
    });
  }, []);

  const addHttpsToUrl = (url) => `${process.env.REACT_APP_API_URL}${url}`;

  const handleshowtest = (category, item) => {
    const product = category.products.find(
      (productItem) => productItem.id === item.id
    );
    if (product) {
      setSelectedItem(product);
      setShowModal(true);
    }
    // Build the product URL with the product ID
    const productUrl = `#/store/products/${item.id}`;

    // Update the browser URL
    window.location.hash = productUrl;
    setSelectedItem(item);
    setShowModal(true);
    const productM = products.find((itemp) => itemp.product.id === item.id);
    setQuantityProduct(
      productsInState.cartItems.find((it) => it.id === productM.product.id)
        ?.quantity
    );
    const updatedImages = productM.images?.map((image) => ({
      original: addHttpsToUrl(image.original),
      thumbnail: addHttpsToUrl(image.thumbnail),
    }));
    setImages(updatedImages);
  };

  const handleClosetest = () => {
    setShowModal(false);
  };

  //
  const [showCat, setShowCat] = useState(false);
  const handleCloseCat = () => setShowCat(false);
  const handleShowCat = () => {
    setShowCat(true);
  };

  const itemsRef = useRef(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [ScrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (e) => {
    setIsMouseDown(true);
    setStartX(e.pageX - itemsRef.current.offsetLeft);
    setScrollLeft(itemsRef.current.ScrollLeft);
  };
  const handleMouseUp = () => {
    setIsMouseDown(false);
  };
  const handleMouseMove = (e) => {
    e.preventDefault();
    if (!isMouseDown) return;
    const x = e.pageX - itemsRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    itemsRef.current.scrollLeft = ScrollLeft - walk;
  };

  const dispatch = useDispatch();
  const AddToCart = (product, title) => {
    // console.log(title);
    dispatch(cartActions.addItem({ product: product, title: title }));
    toast.success("Product Added Successfully");
  };
  const productsInState = useSelector((state) => state.cart);
  const decreseItem = (item) => {
    dispatch(cartActions.decreseItem(item.id));
    setQuantityProduct(
      productsInState.cartItems.find((it) => it.id === item.id)?.quantity
    );
  };

  const increseItem = (item) => {
    dispatch(cartActions.addItem({ product: item }));
    setQuantityProduct(
      productsInState.cartItems.find((it) => it.id === item.id)?.quantity
    );
  };

  const [scrolledbtm, setScrolledbtm] = useState(false);

  const handleBackToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const { t } = useTranslation();
  const { lang } = useContext(LanguageContext);

  return (
    <>
      <Helmet title="Products" />
      <Row className={`rw ${lang === "ar" ? "rw_ar" : ""}`}>
        <Col lg="4" className="comonL">
          <div className="headerCommonL stickyDiv">
            <div className="headSide">
              <Link to={"/store"} className="linktohome">
                <i className="ri-arrow-left-line"></i>
              </Link>
              <img src={commonImg} alt="headSide" />
              <span>Sweet_A2Z Company</span>
            </div>

            <div
              className={`scrollContainer ${
                lang === "ar" ? "checkoutcontainer_ar" : ""
              }`}
            >
              <div
                className="hosScroll"
                ref={itemsRef}
                onMouseDown={handleMouseDown}
                onMouseLeave={handleMouseUp}
                onMouseUp={handleMouseUp}
                onMouseMove={handleMouseMove}
              >
                {!loadingCategories &&
                  categories.length > 0 &&
                  displayedCategories.map((category) => (
                    <NavLink
                      key={category.id}
                      className={(navClass) =>
                        navClass.isActive ? "activeScrollLink" : "ScrollLink"
                      }
                      to={`/store/category/${category.id}`}
                    >
                      {category.name}
                    </NavLink>
                  ))}
              </div>
              <div className="listCategory" onClick={handleShowCat}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  className="fill-categorySliderColor"
                  fill="#796B7a"
                >
                  <path
                    id="grid-2-light"
                    d="M0,34.679A2.679,2.679,0,0,1,2.679,32H8.036a2.679,2.679,0,0,1,2.679,2.679v5.357a2.679,2.679,0,0,1-2.679,2.679H2.679A2.679,2.679,0,0,1,0,40.036Zm1.786,0v5.357a.894.894,0,0,0,.893.893H8.036a.9.9,0,0,0,.893-.893V34.679a.894.894,0,0,0-.893-.893H2.679A.893.893,0,0,0,1.786,34.679ZM0,48.964a2.679,2.679,0,0,1,2.679-2.679H8.036a2.679,2.679,0,0,1,2.679,2.679v5.357A2.679,2.679,0,0,1,8.036,57H2.679A2.679,2.679,0,0,1,0,54.321Zm1.786,0v5.357a.894.894,0,0,0,.893.893H8.036a.9.9,0,0,0,.893-.893V48.964a.9.9,0,0,0-.893-.893H2.679A.894.894,0,0,0,1.786,48.964ZM22.321,32A2.679,2.679,0,0,1,25,34.679v5.357a2.679,2.679,0,0,1-2.679,2.679H16.964a2.679,2.679,0,0,1-2.679-2.679V34.679A2.679,2.679,0,0,1,16.964,32Zm0,1.786H16.964a.894.894,0,0,0-.893.893v5.357a.9.9,0,0,0,.893.893h5.357a.9.9,0,0,0,.893-.893V34.679A.894.894,0,0,0,22.321,33.786ZM14.286,48.964a2.679,2.679,0,0,1,2.679-2.679h5.357A2.679,2.679,0,0,1,25,48.964v5.357A2.679,2.679,0,0,1,22.321,57H16.964a2.679,2.679,0,0,1-2.679-2.679Zm1.786,0v5.357a.9.9,0,0,0,.893.893h5.357a.9.9,0,0,0,.893-.893V48.964a.9.9,0,0,0-.893-.893H16.964A.9.9,0,0,0,16.071,48.964Z"
                    transform="translate(0 -32)"
                  ></path>
                </svg>
              </div>
              <ModalCategory
                title={`${t("Categories")}`}
                show={showCat}
                handleClose={handleCloseCat}
              >
                <div className="Category_modal">
                  <div className="listOfCategory">
                    <ul>
                      {!loadingCategories &&
                        categories?.map((category) => (
                          <li key={category.id} onClick={handleCloseCat}>
                            <Link to={`/store/category/${category.id}`}>
                              {category.name}
                            </Link>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </ModalCategory>
            </div>
          </div>
          {
            !loadingCategories && categories.length > 0 && categoryParams && (
              // categories.map((category)=>(
              <div
                className={`bodyProducts ${
                  lang === "ar" ? "bodyProducts_ar" : ""
                }`}
              >
                <h3>{categoryParams.name}</h3>
                {displayedProducts.map((item) => (
                  <ProductCard
                    key={item.id}
                    item={item}
                    handleShow={() => handleshowtest(categoryParams, item)}
                    AddToCart={AddToCart}
                  />
                ))}
              </div>
            )
            // ))
          }
          {selectedItem && (
            <ProductDetailsModal
              item={selectedItem}
              show={showModal}
              handleClose={handleClosetest}
              images={images} // Adjust this based on your item structure
              quantityProduct={quantityProduct}
              decreseItem={() => decreseItem(selectedItem)} // Make sure to define these functions
              increseItem={() => increseItem(selectedItem)} // Make sure to define these functions
              AddToCart={
                () => AddToCart(selectedItem)
                // handleClosetest();
              }
            />
          )}
          <button
            onClick={handleBackToTop}
            className={`backToTop ${scrolledbtm ? "backToTopActive" : ""}`}
          >
            <i className="ri-arrow-up-line"></i>
          </button>
          <div className="cartBtnn">
            <Link to={"/store/cart"} className="reviewCartBtn">
              <div className="numCartPrice">
                <span className="numproductcart">
                  {productsInState.totalQuantity}
                </span>
                <span className="numCartPrice_price">
                  {productsInState.totalAmount} {t("currency")}
                </span>
              </div>
              <span className="ReviewCart">{t("buttonReaview")}</span>
            </Link>
          </div>
        </Col>
        <Col lg="8" className={`comon ${lang === "ar" ? "comon_ar" : ""}`}>
          <CommonSection />
        </Col>
      </Row>
    </>
  );
};

export default Products;
